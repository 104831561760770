<i18n>
  {
    "en": {
      "statements": "Statements"
    },
    "np": {
      "statements": "लेनदेन"
    }
  }
</i18n>
<template lang="pug">
	.container
		.wallet-form-box.card
			.card-body
				router-link.btn.btn-close(to='/wallet')
					i.fa.fa-times
				h4 {{ $t("statements") }}
				.row
					.col-sm-5
						b-pagination(per-page='20' :total-rows='totalTransactions' v-model='filter.page' size='sm' @change="pageChanged")
					.col-sm-3
					.col-sm-4
						b-select.float-right(v-model='status' :options='statusOptions' size='sm' @change='filterStatus')
				b-table.font-small.text-nowrap( :busy="loading" borderless hover striped responsive :fields='fields' @row-clicked="showDetails" selectable select-mode='single'
					:items='transactionHistory')
					div(slot='status' slot-scope='item' data-toggle='tooltip' :title='item.value')
						span(:class="getCssFromStatus(item.value)")
		b-modal( hide-header hide-footer centered ref='detail' v-if='selectedTransaction')
			.container#invoice
				.print-text.my-5
					h2 Nimbuzz Pay
					h4 3-Sanepa, Lalitpur, Nepal	
					h4 P.O. Box no. 516
					h4 +977-1-5555332
					h4 info.nimbuzzkuraakani@gmail.com
				h4.mt-4.font-weight-bold {{selectedTransaction.servicesName}}
				.text-muted.text-right {{getLocalDate(selectedTransaction.transactionsDate)}}
				hr
				.row.justify-content-center.my-3
					img.icon( class="service"
									:src="selectedTransaction.logo")
				div.bg-light.mt-2.p-4.detail
					.row.mt-1
						.col-12.col-md-5.col-lg-5 Service:
						.col-12.col-md-7.col-lg-7
							b  {{selectedTransaction.service}}
					.row.mt-2 
						.col-12.col-md-5.col-lg-5 Reference No.:
						.col-12.col-md-7.col-lg-7
							b {{selectedTransaction.ref}}
					.row.mt-2 
						.col-12.col-md-5.col-lg-5 Date:  
						.col-12.col-md-7.col-lg-7
							b {{selectedTransaction.date}}
					.row.mt-2 
						.col-12.col-md-5.col-lg-5 Status:
						.col-12.col-md-7.col-lg-7 
							b  {{selectedTransaction.status}}
					.row.mt-2(v-for="info,index in selectedTransaction.serviceIdentity" :key='index')
						.col-12.col-md-5.col-lg-5.text-capitalize {{index}}:
						.col-12.col-md-7.col-lg-7 
							b.text-break  {{info}}

					.row.mt-2
						.col-12.col-md-5.col-lg-5 Total Amount:
						.col-12.col-md-7.col-lg-7 
							b {{selectedTransaction.amount}}
				.mt-4
					button.print-button.btn.btn-link(@click='printNow()') Print Invoice 
		loading-spinner(:loading='loading')
</template>

<script>
import moment from "moment";
import LoadingSpinner from "@/components/LoadingSpinner";
import axios from "@/axios";
export default {
  name: "wallet-statement",
  components: { LoadingSpinner },
  data() {
    return {
      statusOptions: [
        { value: null, text: "Filter By Status", disabled: true },
        {
          value: null,
          text: "All",
        },
        {
          value: "pending",
          text: "Pending",
        },
        { value: "success", text: "Success" },
        {
          value: "failed",
          text: "Failed",
        },
      ],
      status: null,
      transactionHistory: [],
      fields: [
        "service",
        "date",
        "status",
        "debit (Rs.)",
        "credit (Rs.)",
        "balance (Rs.)",
      ],
      loading: true,
      selectedTransaction: {},
      totalTransactions: 0,
      REMOTE_SERVER: process.env.VUE_APP_API_SERVER,
      filter: {
        page: 1,
        conditions: {},
      },
    };
  },
  methods: {
    fetchTransactions() {
      if (this.status) {
        this.filter.conditions.status = this.status;
      } else {
        delete this.filter.conditions.status;
      }
      this.loading = true;
      axios.post(`api/v4/transactions`, this.filter).then((r) => {
        if (r.data) {
          this.transactionHistory = this.formatTransactions(r.data.data.transactions);
          this.totalTransactions = r.data.data.meta[0].total;
          this.loading = false;
        }
      });
    },

    filterStatus() {
      this.loading = true;
      if (this.filter.conditions.status === null) {
        delete this.filter.status;
      }
      this.filter.page = 1;
      this.fetchTransactions();
    },
    formatTransactions(transactions) {
      if (transactions) {
        transactions.forEach((tra) => {
          tra.date = this.getLocalDate(tra.created);
          tra.service = tra.title;
          tra.status = tra.status.toUpperCase();
          tra["balance (Rs.)"] = `${tra.balance}`;
          tra[tra.type] = tra.amount;

          if (tra.type === "transfer") {
            if (tra.title.includes("Payment to")) {
              tra["debit (Rs.)"] = tra.amount;
            } else {
              tra["credit (Rs.)"] = tra.amount;
            }
          } else {
            if (tra.type === "debit") {
              tra["debit (Rs.)"] = tra.amount;
            } else {
              tra["credit (Rs.)"] = tra.amount;
            }
          }
        });
      }
      return transactions;
    },
    pageChanged(page) {
      this.$router.push({ path: "/statements", query: { page } });
      this.fetchTransactions();
    },
    printNow() {
      window.open(
        `${this.REMOTE_SERVER}/api/v4/transactions/export/${this.selectedTransaction._id}/${this.selectedTransaction.ref}`,
        "_blank"
      );
    },
    showDetails(row) {
      this.selectedTransaction = row;
      this.$refs["detail"].show();
    },
    getLocalDate(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
    getCssFromStatus(status) {
      if (typeof status !== "boolean") {
        switch (status.toLowerCase()) {
          case "success":
            return "fa fa-check";
          case "hold":
            return "fa fa-pause-circle";
          case "failed":
            return "fa fa-times-circle";
          case "pending":
            return "fa fa-spinner";
          default:
            return "fa fa-wrench";
        }
      }
    },
  },
  mounted() {
    this.filter.page = this.$route.query.page ?? 1;
    this.fetchTransactions();
  },
};
</script>

<style scoped>
.print-text {
  display: none;
}

.icon {
  max-height: 100px;
}

@media print {
  .print-text {
    display: block;
  }
}
</style>
